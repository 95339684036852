import { useParams } from "@remix-run/react";
import { toSeoString } from '../../../play-core/utils/to-seo-string';
import { useHydrated } from '../hooks/use-hydrated';
export const usePacPageId = (route, initialData) => {
  const {
    topicName
  } = useParams();
  const isHydrated = useHydrated();
  const search = isHydrated ? window.location.search : '';
  switch (route.pageId) {
    case 'home':
      return initialData.pacPageConfigs?.landingPage.id;
    case 'micropage':
      return new URLSearchParams(search).get('pageId');
    case 'topic':
      {
        const topic = initialData.topics?.find(t => toSeoString(t.title) === toSeoString(topicName));
        const topicPage = topic && initialData.pacPageConfigs?.topicPages[topic.urn];
        return topicPage?.id;
      }
    default:
  }
};